@font-face {
    font-family: PoppinsRegular;
    src: url(./assets/fonts/Poppins/Poppins-Regular.ttf)
  }
  
  @font-face {
    font-family: PoppinsBold;
    src: url(./assets/fonts/Poppins/Poppins-Bold.ttf)
  }
  
  @font-face {
    font-family: PoppinsLight;
    src: url(./assets/fonts/Poppins/Poppins-Light.ttf)
  }
  
  body, html {
    font-family: PoppinsRegular;
  }
  
  #root {
    font-family: PoppinsRegular;
  }
  
  .root {
    font-family: PoppinsRegular;
  }